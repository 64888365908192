import React from 'react'

import './projects.css'

const Projects = () => {
    return (
        <>
           <h1> Projects </h1>
        </>
    )
}

export default Projects