import React from 'react'
import './contact.css'



const Contact = () => {
    return (
        <>
           <h1> Contact </h1>
        </>
    )
}

export default Contact