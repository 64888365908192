import React from 'react'

import Navbar from '../../components/Navbar/Navbar'




const About = () => {
    return (
        <>
           <h1> about </h1>
        </>
    )
}

export default About