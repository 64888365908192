import React from 'react'

import './home.css'

import Background from '../../components/Background/background'




const Home = () => {
    return (
        <>
 <Background/>

        </>
    )
}

export default Home 